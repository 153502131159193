.react-player {
  video {
    object-fit: cover;
  }

  &.intro {
    video {
      object-position: 50% 0;
      height: 850px !important;
      mask-image: linear-gradient(180deg, rgb(0, 0, 0) 90%, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

.video-image {
  object-position: 50% 0;
  height: 850px !important;
  mask-image: linear-gradient(180deg, rgb(0, 0, 0) 90%, rgb(0, 0, 0) 90%, rgba(0, 0, 0, 0) 100%);
}
