.contact-us-form {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    background-color: #28303E !important;
    color: white !important;
    -webkit-box-shadow: 0 0 0 1000px #28303E inset !important;
    -webkit-text-fill-color: white !important;
    border-color: #7C838E !important;
    caret-color: white;
  }

  input:-webkit-autofill:disabled {
    background-color: #475467 !important;
    color: white !important;
    -webkit-box-shadow: 0 0 0 1000px #475467 inset !important;
    -webkit-text-fill-color: white !important;
    opacity: 0.5;
    caret-color: white;
  }

  textarea::selection, input::selection {
    caret-color: white;
    background: #E2D8F5;
    color: #0C111D;
  }
}
