.swiper-clients {
  width: 100%;
  padding-bottom: 32px;
  height: 90px;
  box-sizing: content-box;
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.8) 10%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0.8) 90%,
    rgba(255, 255, 255, 0) 100%
  );

  @media (min-width: 496px) {
    width: 500px;
    height: 124px;
  }

  @media (min-width: 768px) {
    width: 750px;
  }

  @media (min-width: 1280px) {
    width: 950px;
  }

  &.hot-lead-b {
    width: 350px;

    @media (min-width: 496px) {
      width: calc(500px - 2%);
    }

    @media (min-width: 768px) {
      width: calc(700px - 2%);
    }

    @media (min-width: 850px) {
      width: calc(850px - 2%);
    }

    @media (min-width: 1050px) {
      width: calc(1050px - 2%);
    }

    @media (min-width: 1280px) {
      width: calc(1280px - 2%);
    }
  }

  .swiper-wrapper {
    margin: 0 !important;
  }
}
