.scrollable-content {
  scrollbar-gutter: stable;
  height: -webkit-fill-available;

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background-color: #ebeaf0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}

.opacity-duration {
  transition-property: opacity;
  transition-duration: 1000ms;
}

.arrow-animation-small {
  animation: moveSmallArrowUp 900ms ease-out forwards;
}
.arrow-animation-large {
  animation: moveLargeArrowUp 900ms ease-out forwards;
}

@keyframes moveSmallArrowUp {
  from {
    top: calc(57.73px * 5);
    left: calc(-100px * 5);
  }
  to {
    top: 57.73px;
    left: -100px;
  }
}

@keyframes moveLargeArrowUp {
  from {
    bottom: calc(-57.73px * 5);
    right: calc(100px * 5);
  }
  to {
    bottom: 57.73px;
    right: -100px;
  }
}
