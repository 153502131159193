.object-fit-cover {
  video {
    object-fit: cover;
  }
}

.rounded {
  video {
    border-radius: 16px;
  }
}

.object-position-right {
  video {
    object-position: right;
  }
}
