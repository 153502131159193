.puzzle-image {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-30deg) rotateY(-45deg);
  right: -100px;
  width: 578px;
  height: 490px;
  border-radius: 24px;
}

.mask {
  mask-image: linear-gradient(180deg, rgb(0, 0, 0) 30%, rgb(0, 0, 0) 30%, rgba(0, 0, 0, 0) 100%);
}

.puzzle-wrapper {
  left: -20.7px;
  width: 100%;
  height: 100%;
  top: 20.7px;
}

.puzzle-animation {
  .cube-wrapper {
    width: 100px;
    height: 100px;
    animation: slideIn 1.1s linear forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    .cube {
      width: 100%;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      transform: rotateX(-30deg) rotateY(45deg);

      .side {
        position: absolute;
        width: 55.43px;
        height: 55.43px;

        &.top {
          transform: rotateX(90deg) translateZ(83.145px);
          background: linear-gradient(164.68deg, #db7eb8 20.73%, #c93d94 78.75%);
          width: 60px;
          animation: narrowDown 1.1s linear forwards;
        }

        &.front {
          box-shadow: 0 0 30px -3px rgba(255, 255, 255, 0.5);
          transform: rotateY(-90deg) translateZ(27.715px);
          overflow: hidden;
          position: relative;
        }

        &.left {
          transform: rotateX(0deg) translateZ(27.715px);
          background: linear-gradient(164.68deg, #db7eb8 20.73%, #c93d94 78.75%);
          width: 60px;
          animation: narrowDown 1.1s linear forwards;
        }
      }
    }
  }
}


.puzzle-animation {
  .hole-fade-out {
    animation: holeFadeOut 1.1s linear forwards;
  }

  .plume-fade-out {
    animation: plumeFadeOut 1.1s linear forwards;
  }

  .shadow-fade-out {
    animation: shadowFadeOut 1.1s linear forwards;
  }
}

@keyframes holeFadeOut {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes shadowFadeOut {
  0% {
    box-shadow: 0 0 30px -3px rgba(255, 255, 255, 0.5);
  }

  60% {
    box-shadow: 0 0 30px -3px rgba(255, 255, 255, 0.4);
  }

  100% {
    box-shadow: 0 0 30px -3px rgba(255, 255, 255, 0);
  }
}

@keyframes plumeFadeOut {
  0% {
    opacity: 0.8;
  }

  60% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-260.9px) translateY(122.9px);
  }

  40% {
    transform: translateX(16.1px) translateY(-16.1px);
    opacity: 1;
  }

  99% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateX(59.2px) translateY(-37.6px);
  }
}

@keyframes narrowDown {
  0% {
    width: 60px;
  }

  40% {
    width: 60px;
  }

  100% {
    width: 0;
  }
}
