.cursor {
  left: 20.429%;
  top: 33.792%;
  position: absolute;
  width: 17.143%;
  height: 26.331%;
  border-radius: 50%;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 583.33% 379.77%;
  background-position: 14.3% 28%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.cursorLoad {
  animation: cursorLoadAnimation 10s infinite;
  animation-timing-function: linear;
  animation-delay: 3s;
}

@supports (-webkit-backdrop-filter: none) {
  .cursorLoad {
    animation: none; /* Disable animation in Safari */
  }
}

@keyframes cursorLoadAnimation {
  0% {
    left: 20.429%;
    top: 33.792%;
    background-position: 14.3% 28%;
  }

  33% {
    left: 43.714%;
    top: 79.872%;
    background-position: 42.289% 90.586%;
  }

  66% {
    left: 67.286%;
    top: 49.803%;
    background-position: 70.9% 49.7%;
  }

  100% {
    left: 20.429%;
    top: 33.792%;
    background-position: 14.3% 28%;
  }
}
