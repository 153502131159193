.industries-swiper {
  width: 100%;
  height: 60px;
  margin-bottom: -10px;

  @media (max-width: 495px) {
    height: 44px;
  }

  mask-image:linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.8) 2%, rgba(0,0,0,1) 20%, rgba(0,0,0,1) 80%, rgba(0,0,0,0.8) 98%, rgba(255,255,255,0) 100%);

  .swiper-wrapper {
    display: flex;
    .swiper-slide {
      width: fit-content !important;
    }
  }
}
