.mission-swiper {
  display: grid;
  padding-bottom: 25px;
  margin: 0 55px;

  .swiper-pagination {
    bottom: 0;

    .swiper-pagination-bullet {
      background-color: #EAECF0;
    }

    .swiper-pagination-bullet-active {
      background-color: #D25DA6;
    }
  }
}
