.flashLoad {
  animation: load 2s;
}

.slideMoveControlAnimation {
  animation: moveControl 10s;
}

.slideMoveAfterAnimation {
  animation: moveAfter 10s;
}

.slideMoveBeforeAnimation {
  animation: moveBefore 10s;
}

@keyframes load {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveControl {
  0% {
    left: 51.6%;
  }

  25% {
    left: 1.5%;
  }

  50% {
    left: 51.6%;
  }

  75% {
    left: 99%;
  }

  100% {
    left: 51.6%;
  }
}

@keyframes moveAfter {
  0% {
    clip-path: inset(0px 48.4% 0px 0px);
  }

  25% {
    clip-path: inset(0px 98.5% 0px 0px);
  }

  50% {
    clip-path: inset(0px 48.4% 0px 0px);
  }

  75% {
    clip-path: inset(0px 1% 0px 0px);
  }

  100% {
    clip-path: inset(0px 48.4% 0px 0px);
  }
}

@keyframes moveBefore {
  0% {
    clip-path: inset(0px 0px 0px calc(100% - 48.4%));
  }

  25% {
    clip-path: inset(0px 0px 0px calc(100% - 98.5%));
  }

  50% {
    clip-path: inset(0px 0px 0px calc(100% - 48.4%));
  }

  75% {
    clip-path: inset(0px 0px 0px calc(100% - 1%));
  }

  100% {
    clip-path: inset(0px 0px 0px calc(100% - 48.4%));
  }
}
