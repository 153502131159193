.feedback-swiper {
  padding-right: 16px !important;
  padding-left: 16px !important;
  mask-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.8) 1.5%,
    rgba(0, 0, 0, 1) 20%,
    rgba(0, 0, 0, 1) 80%,
    rgba(0, 0, 0, 0.8) 98.5%,
    rgba(255, 255, 255, 0) 100%
  );

  @media (min-width: 1280px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
    mask-image: none;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
  }
}

.feedbacks-background-video {
  @media (min-width: 1280px) {
    clip-path: inset(0 17% 0 21%);
  }

  video {
    object-fit: cover;
  }
}
